import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/belt`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer l'historique des passages de ceinture pour un adhérent avec son ID
export const getBeltHistoryByAdherent = async (adherentId) => {
  try {
    const response = await axios.get(
      `${apiUrl}/getBeltHistoryByAdherent/${adherentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération de l'historique des passages de ceinture:",
      error
    );
  }
};

// Fonction pour récupérer la liste des passages de ceinture de tous les adhérents
export const getBeltsHistory = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getBeltsHistory`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;  // Retourne directement les données attendues
  } catch (error) {
    console.error("Erreur lors du chargement des passages de ceinture:", error);
    throw error; // Il est mieux de relancer l'erreur pour la gérer plus haut dans la chaîne
  }
};

// Fonction pour récupérer la liste des ceintures
export const getBeltsList = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getBeltsList`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des ceintures:", error);
  }
};

// Fonction pour récupérer un passage de ceinture avec son ID
export const getBeltHistoryById = async (id) => {
  try {
    const response = await axios.get(`${apiUrl}/getBeltHistoryById/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération du passage de grade:", error);
  }
};

// Fonction pour récupérer le décompte de chaque passage par ceinture pour les adhérents licenciées sur la saison active
export const getBeltCountBySeason = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getBeltCountBySeason`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des passages de ceinture:", error);
  }
};


/// POST
// Fonction pour ajouter un passage de ceinture
export const addBeltHistory = async (data) => {
  try {
    await axios.post(`${apiUrl}/addBeltHistory`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de l'ajout du passage de grade:", error);
  }
};

/// PUT
// Fonction pour modifier un passage de ceinture avec son ID
export const editBeltHistory = async (id, data) => {
  try {
    await axios.put(`${apiUrl}/editBeltHistory/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  catch (error) {
    console.error("Erreur lors de la modification du passage de grade:", error);
  }
}


/// DELETE
// Fonction pour supprimer un passage de ceinture avec son ID
export const deleteBeltHistory = async (id) => {
  try {
    await axios.delete(`${apiUrl}/deleteBeltHistory/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Erreur lors de la suppression:", error);
  }
};