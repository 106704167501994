import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "./../../../hooks/useScollToTop";
import { ToastContainer, toast } from "react-toastify";
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import Button from "./../../../components/Button/Button";
import "react-toastify/dist/ReactToastify.css";
import EditRequestModal from "./EditRequestModal";
import AddRequestModal from "./AddRequestModal";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const RequestsAdmin = () => {
  useScrollToTop();

  const [requestsData, setRequestsData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fonction pour récupérer les requêtes depuis l'API externe
  const fetchRequests = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.monappliclub.com/api/requests/RequestsByAssociationId/1"
      );
      if (Array.isArray(response.data)) {
        setRequestsData(response.data);
      } else {
        throw new Error("Les données reçues ne sont pas un tableau.");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des requêtes :", error);
      toast.error("Erreur lors de la récupération des requêtes");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  const handleEditClick = (request) => {
    setCurrentRequest(request);
    setShowEditModal(true);
  };

  const handleAddClick = () => {
    setShowAddModal(true);
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette requête ?")) {
      try {
        await axios.delete(
          `https://api.monappliclub.com/api/requests/deleteRequest/${id}`
        );
        // Supprime la requête localement après suppression côté serveur
        setRequestsData((prevRequests) =>
          prevRequests.filter((item) => item.id !== id)
        );
        toast.success("Requête supprimée avec succès !");
      } catch (error) {
        console.error("Erreur lors de la suppression de la requête :", error);
        toast.error("Erreur lors de la suppression de la requête.");
      }
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    fetchRequests(); // Rafraîchit les données après modification
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
    fetchRequests(); // Rafraîchit les données après ajout
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Requêtes
      </h2>
      <DashboardNavigation />
      <div className="flex flex-col items-center w-full px-4 mt-4">
        {loading ? (
          <p>Chargement des données...</p>
        ) : (
          <>
            <Button
              text="Ajouter une Requête"
              onClick={handleAddClick}
              className="mb-4"
            />
            <RequestsTable
              requestsData={requestsData}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
            />
          </>
        )}
      </div>
      <ToastContainer />
      {showEditModal && (
        <EditRequestModal
          isOpen={showEditModal}
          onClose={handleCloseEditModal}
          request={currentRequest}
          refreshList={fetchRequests}
        />
      )}
      {showAddModal && (
        <AddRequestModal
          isOpen={showAddModal}
          onClose={handleCloseAddModal}
          refreshList={fetchRequests}
        />
      )}
    </div>
  );
};

const RequestsTable = ({ requestsData, onEditClick, onDeleteClick }) => {
  return (
    <div className="bg-gray-100 dark:bg-gray-500 shadow-lg max-w-full mx-auto font-montserrat pb-10">
      <div className="overflow-x-auto">
        <table className="table-auto bg-white dark:bg-slate-300 font-montserrat w-full mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Titre
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Description
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Priorité
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Statut
              </th>
              <th
                scope="col"
                className="px-5 py-3 border-b-2 border-gray-200 text-center text-xs font-semibold uppercase tracking-wider text-white"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {requestsData.length > 0 ? (
              requestsData.map((request) => (
                <tr
                  key={request.id}
                  className="hover:bg-gray-100 dark:hover:bg-gray-400 hover:dark:text-white dark:text-black text-sm"
                >
                  <td className="px-5 py-3">{request.title}</td>
                  <td
                    className="px-5 py-3"
                    dangerouslySetInnerHTML={{ __html: request.description }}
                  ></td>
                  <td className="px-5 py-3">
                    <span
                      className={`px-2 py-1 rounded text-white text-xs ${
                        request.priority === "High"
                          ? "bg-red-500"
                          : request.priority === "Medium"
                          ? "bg-orange-500"
                          : "bg-yellow-500"
                      }`}
                    >
                      {request.priority === "High"
                        ? "Urgent"
                        : request.priority === "Medium"
                        ? "Moyenne"
                        : "Basse"}
                    </span>
                  </td>
                  <td className="px-5 py-3">
                    <span
                      className={`px-2 py-1 rounded text-white text-xs ${
                        request.status === "Pending"
                          ? "bg-yellow-500"
                          : request.status === "In Progress"
                          ? "bg-blue-500"
                          : "bg-green-500"
                      }`}
                    >
                      {request.status === "Pending"
                        ? "En attente"
                        : request.status === "In Progress"
                        ? "Traitement en cours"
                        : "Terminée"}
                    </span>
                  </td>
                  <td className="px-5 py-3 flex items-center justify-center space-x-2">
                    <ButtonEdit onClick={() => onEditClick(request)} />
                    <ButtonDelete onClick={() => onDeleteClick(request.id)} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4 px-6 text-gray-800">
                  Aucune requête disponible.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RequestsAdmin;
