import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import Button from "./../../../components/Button/Button";
import ButtonEdit from "./../../../components/Button/ButtonEdit";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import {
  getRoles,
  getAllUsers,
  getLatestUsers,
  deleteUser as deleteUserAPI,
  assignAdherentToUser,
} from "./../../../services/userServices";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "./../../Home/ScrollIndicator";
import AddUserModal from "./AddUserModal";
import EditUserModal from "./EditUserModal";
import ManageAdherentsModal from "./AdherentManagementModal";
import { ToastContainer, toast } from "react-toastify";

const UserListing = () => {
  useScrollToTop();
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [roles, setRoles] = useState({});
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;
  const [showModal, setShowModal] = useState(false); // État pour contrôler l'ouverture de la modale
  const [showEditModal, setShowEditModal] = useState(false);
  const [showManageAdherentsModal, setShowManageAdherentsModal] =
    useState(false);
  const [editUserId, setEditUserId] = useState(null);
  const [selectedAdherent, setSelectedAdherent] = useState(null); // État pour stocker l'adhérent sélectionné
  const [currentUserId, setCurrentUserId] = useState(null);

  const fetchData = async () => {
    // Définition de fetchData ici
    try {
      const usersData = await getAllUsers();
      const rolesData = await getRoles();
      if (!Array.isArray(rolesData)) {
        throw new Error("Expected rolesData to be an array");
      }
      const rolesMap = rolesData.reduce((acc, role) => {
        acc[role.id] = role.nom;
        return acc;
      }, {});
      setUsers(usersData);
      setDisplayedUsers(usersData);
      setRoles(rolesMap);
    } catch (error) {
      console.error("Erreur lors du chargement des données:", error);
      toast.error("Erreur lors du chargement des données");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearchChange = (e) => {
    const searchTermLocal = e.target.value;
    setSearchTerm(searchTermLocal);
    if (!searchTermLocal) {
      setDisplayedUsers(users);
    } else {
      filterUsers(searchTermLocal);
    }
  };

  const filterUsers = (term) => {
    const filteredUsers = users.filter(
      (user) =>
        user.nom.toLowerCase().includes(term.toLowerCase()) ||
        user.prenom.toLowerCase().includes(term.toLowerCase())
    );
    setDisplayedUsers(filteredUsers);
  };

  // Fonction pour supprimer un utilisateur
  const deleteUser = async (userId) => {
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer cet utilisateur ? ID: ${userId}`
      )
    ) {
      try {
        const message = await deleteUserAPI(userId);
        toast.success(message);
        setUsers(users.filter((user) => user.id !== userId));
      } catch (error) {
        console.error("Erreur lors de la suppression de l'utilisateur:", error);
        toast.error("Erreur lors de la suppression de l'utilisateur");
      }
    }
  };

  const getRecentUsers = async () => {
    try {
      const recentUsers = await getLatestUsers(); // Utilisation de la nouvelle fonction
      setDisplayedUsers(recentUsers);
    } catch (error) {
      toast.error("Erreur lors de la récupération des derniers utilisateurs");
    }
  };

  const getAllUsersAgain = async () => {
    setDisplayedUsers(users); // Réaffiche tous les utilisateurs
  };

  const handleAssignAdherent = async (userId) => {
    try {
      // Appel de la fonction pour récupérer la liste des adhérents et assigner un adhérent à l'utilisateur
      const adherentId = ""; // Vous pouvez définir l'ID de l'adhérent ici
      const message = await assignAdherentToUser(userId, adherentId);
      setShowModal(true);
      toast.success(message);
    } catch (error) {
      console.error(
        "Erreur lors de l'assignation de l'adhérent à l'utilisateur:",
        error
      );
      toast.error("Erreur lors de l'assignation de l'adhérent à l'utilisateur");
    }
  };

  // Fonction pour afficher le rôle sous forme de badge
  const getRoleDisplay = (roleId) => {
    switch (roleId) {
      case 1:
        return { name: "Administrateur", className: "bg-red-500 text-white" };
      case 2:
        return { name: "Utilisateur", className: "bg-blue-500 text-white" };
      case 3:
        return { name: "Enseignant", className: "bg-green-500 text-white" };
      default:
        return { name: "Rôle inconnu", className: "bg-gray-500 text-white" };
    }
  };

  const handleAddUser = () => {
    setShowModal(true);
  };

  const openEditModal = (userId) => {
    setEditUserId(userId);
    setShowEditModal(true);
  };

  // Fonction pour ouvrir la modale de gestion des adhérents
  const openManageAdherentsModal = (userId) => {
    setEditUserId(userId); // Définir l'ID de l'utilisateur pour lequel modifier les adhérents
    setShowManageAdherentsModal(true); // Ouvrir la modale
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ADMINISTRATEUR
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Listing Utilisateurs
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="w-full max-w-[1280px] mx-auto">
        {/* Section des boutons */}
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between space-y-4 lg:space-y-0 lg:gap-4">
          {/* Bouton Ajouter Utilisateur à gauche */}
          <div className="w-full lg:w-auto">
            <Button
              text="Ajouter Utilisateur"
              onClick={handleAddUser}
              icon={FaPlus}
              className="w-full lg:w-auto gap-2"
            />
          </div>

          {/* Les autres boutons à droite */}
          <div className="flex flex-col lg:flex-row lg:space-x-4 w-full lg:w-auto lg:ml-auto lg:space-y-0 space-y-4">
            <Button
              text="Afficher les derniers inscrits"
              onClick={getRecentUsers}
              className="w-full lg:w-auto"
            />
            <Button
              text="Afficher Tous"
              onClick={getAllUsersAgain}
              className="w-full lg:w-auto"
            />
          </div>
        </div>

        {/* Zone de recherche */}
        <div className="mt-4 mb-4">
          <input
            className="input input-bordered w-full"
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        {/* Modals */}
        <AddUserModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          refreshList={() => {
            const fetchUsers = async () => {
              const usersData = await getAllUsers();
              setUsers(usersData);
              setDisplayedUsers(usersData);
            };
            fetchUsers();
          }}
        />

        <EditUserModal
          isOpen={showEditModal}
          userId={editUserId}
          onClose={() => setShowEditModal(false)}
          refreshList={fetchData}
        />
      </div>

      <div className="overflow-x-auto w-full max-w-[1280px] mx-auto">
        <table className="min-w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md">
          <thead>
            <tr className="bg-gray-300 dark:bg-gray-900">
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                Photo
              </th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                Nom Prénom
              </th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                Email
              </th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                Rôle
              </th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                Gestion Adhérent
              </th>
              <th className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
            {displayedUsers.map((user, index) => (
              <tr key={user.user_id} className="text-left">
                <td className="px-4 py-2 border-none dark:border-gray-600 dark:text-gray-200 flex justify-center items-center">
                  <img
                    className="rounded-full w-10 h-10 object-cover"
                    src={user.photoURL || "/img/user_avatar.jpg"}
                    alt="Avatar"
                  />
                </td>
                <td className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                  {user.nom} {user.prenom}
                </td>
                <td className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                  {user.email}
                </td>
                <td className="px-4 py-2 border-none dark:border-gray-600 dark:text-gray-200 justify-center">
                  <span
                    className={`badge ${
                      getRoleDisplay(user.role_id).className
                    }`}
                  >
                    {getRoleDisplay(user.role_id).name}
                  </span>
                </td>
                <td className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                  {user.adherents && user.adherents.length > 0 ? (
                    user.adherents.map((adherent, idx) => (
                      <div key={`${user.user_id}-${idx}`}>
                        {adherent.nom} {adherent.prenom}
                      </div>
                    ))
                  ) : (
                    <div>Aucun</div>
                  )}
                </td>
                <td className="px-4 py-2 border dark:border-gray-600 dark:text-gray-200">
                  <div className="flex justify-center items-center w-full h-full gap-2">
                    <ButtonEdit
                      onClick={() => openEditModal(user.id)}
                      className=""
                      hoverLabel="Éditer"
                    />
                    <button
                      className="w-8 h-8 bg-green-500 text-white rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-green-700 flex items-center justify-center"
                      title="Gérer Adhérents"
                      onClick={() => openManageAdherentsModal(user.id)} // Cette fonction ouvre la modale de gestion des adhérents
                    >
                      +/-
                    </button>
                    <ButtonDelete
                      onClick={() => deleteUser(user.id)}
                      className=""
                      hoverLabel="Supprimer"
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ManageAdherentsModal
        isOpen={showManageAdherentsModal}
        onClose={() => setShowManageAdherentsModal(false)}
        userId={editUserId}
        refresh={fetchData} // Assurez-vous que cette fonction recharge les données pertinentes
      />
      <ToastContainer />
    </div>
  );
};

export default UserListing;
